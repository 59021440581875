import React, {useState, useEffect} from 'react';

import EntryMenu from "../components/EntryMenu"
import EntryCard from "../components/EntryCard"
import LoadSpinner from "../components/LoadSpinner"
import { useParams } from "react-router-dom";
import { useAuth } from '../auth';
import { styled } from "@mui/system";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export default function EntryView(props) {
    const { id } = useParams();
    const [entry, setEntry] = useState(null)
    const auth = useAuth()
    
      useEffect(() => {
        const updateData = async (id) => {
          const response = await auth.api.get(`/api/entry/${id}`)
          setEntry(response.data)
        }

        updateData(id);
      }, [auth.api, id]);

    return (
        <>
            <EntryMenu />
            <Offset sx={{mb: 1}}/>
            {entry ? <EntryCard entry={entry} /> : <LoadSpinner />}

        </>
    )
}