import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import Container from '@mui/material/Container';
import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../auth';
import { styled } from '@mui/material/styles';


// see https://material-ui.com/guides/composition/
function ListItemLink(props) {
  const { icon, itemID, primary, secondary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <RouterLink to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText key={`itemtext-${itemID}`} primary={primary} secondary={secondary} />
      </ListItem>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};


const StyledListSubheader = styled(ListSubheader)(({ theme }) => ({
  backgroundColor: '#E5E5E5',
  color: '#252525'
}));


export default function Results(props) {
  const auth = useAuth()

  const [searchResult, setSearchResult] = useState({matches: [], suggestions: [], entries: {}})
  // const [matches, setMatches] = useState([]);
  // const [suggestions, setSuggestions] = useState([]);
  // const [entries, setEntries] = useState({});

  const updateData = async (searchTerm) => {
    // const source = auth.api.CancelToken.source()
    // signal.addEventListener('abort', () => {
    //   source.cancel()
    // })
    var payload = { keyword: searchTerm, limit: 10 }
    const response = await auth.api.post('/api/search', payload, {
      // cancelToken: source.token,
    })
    setSearchResult(response.data)
  }


  useEffect(() => {
    if (props.searchTerm) {
      updateData(props.searchTerm);
    } else {
      setSearchResult({matches: [], suggestions: [], entries: {}})
    }

  }, [props.searchTerm]);

  return (
    <Container>
      <div />
      <List dense subheader={<li />}>
        {searchResult.matches.length > 0 ?
          <li key={'section-matches'} >
            <ul>
              <StyledListSubheader key='result-list-header'><Typography variant="h6" noWrap>Matches</Typography></StyledListSubheader>
              {searchResult.matches.map((itemKeyword) => (
                <React.Fragment key={`div-${itemKeyword}`}>
                  {searchResult.entries[itemKeyword].map((entry, index) => {
                    var t = [];
                    entry['trans'].map((row, i) => {
                      t.push(row['english'])
                    });
                    return (
                      <ListItemLink key={`item-matches-${entry.ID}`} itemID={entry.ID} to={`/entry/${entry.ID}`} primary={`${entry.word}`} secondary={`${t.join("; ")}`} />
                    )
                  })}
                </React.Fragment>

              ))}
            </ul>
          </li> : null}

        {searchResult.suggestions.length > 0 ?
          <li key={'section-suggestions'}>
            <ul >
              <StyledListSubheader key='suggestions-list-header'><Typography variant="h6" noWrap>Suggestions</Typography></StyledListSubheader>
              {searchResult.suggestions.map((itemKeyword) => (
                <React.Fragment key={`div-${itemKeyword}`}>
                  {/* <ListSubheader component="div" id="nested-list-subheader"><Typography className={classes.title} variant="h7" noWrap style={{fontSize: "1.2em", fontStyle: "italic"}}>Matched on: {itemKeyword}</Typography></ListSubheader> */}
                  {searchResult.entries[itemKeyword].map((entry, index) => {
                    var t = [];
                    entry['trans'].map((row, i) => {
                      t.push(row['english'])
                    });
                    return (
                      <ListItemLink key={`item-suggestions-${entry.ID}`} itemID={entry.ID} to={`/entry/${entry.ID}`} primary={`${entry.word}`} secondary={`${t.join("; ")}`} />
                    )
                  })}
                </React.Fragment>

              ))}
            </ul>
          </li> : null}

      </List>
    </Container>
  );
}
