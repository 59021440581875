import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";

// based on https://usehooks.com/useAuth/

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};


function setLSToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
}

function delLSToken() {
    localStorage.removeItem('token')
}

function getLSToken() {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    if (userToken) {
        return userToken;
    }
    return null
}


function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [token, setToken] = useState(getLSToken());
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const signin = async (email, password, remember) => {
        setIsLoading(true)
        let error = null;
        try {
            const response = await axios.post(`/auth/login`, { username: email, password })
            // setUser(response.data.claims.username)
            // setRole(response.data.claims.role)
            if (remember) {
                setLSToken(response.data.token)
            }
            setToken(response.data.token)
        } catch (err) {
            console.log("hello error")
            setUser(null)
            setRole(null)
            delLSToken()
            setToken(null)
            if (err.response && err.response.status === 401) {
                error = "Login failed! Invalid user and/or password."
            } else {
                error = "Login failed! Server could not be reached."
            }
            console.log(err.response)

        }
        setIsLoading(false);
        return error
    };

    const signout = () => {
        setUser(null)
        setRole(null)
        delLSToken()
        setToken(null)
    };


    const api = axios.create({
        headers: {
            Authorization: `Bearer ${token}`
        }
    });

    // based on https://www.bigbinary.com/react-best-practices/logout-on-401
    const responseSuccessHandler = response => {
        return response;
    };

    const responseErrorHandler = error => {
        if (error.response.status === 401 || error.response.status === 403) {
            signout()
        }
        return Promise.reject(error);
    }

    api.interceptors.response.use(
        response => responseSuccessHandler(response),
        error => responseErrorHandler(error)
    );


    useEffect(() => {
        setIsLoggedIn(token !== null)
        if (token !== null) {
            var decoded = jwt_decode(token);
            setUser(decoded.username)
            setRole(decoded.role)
        }
    }, [token]);




    return {
        user,
        role,
        token,
        signin,
        signout,
        isLoggedIn,
        isLoading,
        api
    };
}

//   import { AxiosResponse, AxiosError } from 'axios'

// axios.get('foo.com')
//   .then((response: AxiosResponse) => {
//     // Handle response
//   })
//   .catch((reason: AxiosError) => {
//     if (reason.response!.status === 400) {
//       // Handle 400
//     } else {
//       // Handle else
//     }
//     console.log(reason.message)
//   })