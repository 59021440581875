import Dictionary from "./pages/Dictionary";
import LoginPage from "./pages/Login";
import { ProvideAuth } from "./auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";

function App() {
  return (
    <ProvideAuth>
      <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/login">
            <LoginPage />
          </Route>
            <Dictionary />
        </Switch>
      </Router>
      </ThemeProvider>
    </ProvideAuth>
  );
}

export default App;
