import React, { useState } from "react";

import Nav from "../components/Nav"
import { Switch } from "react-router-dom";
import PrivateRoute from '../components/PrivateRoute';
import Results from "../components/Results"
import EntryView from "./EntryView";
import EntryEdit from "./EntryEdit";
import CreateEntry from "./CreateEntry";
import { styled } from "@mui/system";
import MainDrawer from "../components/MainDrawer";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

// timeout options: https://stackoverflow.com/questions/42217121/how-to-start-search-only-when-user-stops-typing
const Search = (props) => {
    return (
        <>
            <Nav searchTerm={props.searchTerm} setSearchTerm={props.setSearchTerm} setShowDrawer={props.setShowDrawer}/>
            <Offset sx={{ mb: 1.5 }} />
            <Results searchTerm={props.searchTerm} />
            <MainDrawer showDrawer={props.showDrawer} setShowDrawer={props.setShowDrawer}/>
        </>
    )
}
const Dictionary = () => {
    const [searchTerm, setSearchTerm] = useState("")
    const [showDrawer, setShowDrawer] = useState(false)


    return (
        <Switch>
            <PrivateRoute path="/" exact={true}>
                <Search searchTerm={searchTerm} setSearchTerm={setSearchTerm} showDrawer={showDrawer} setShowDrawer={setShowDrawer}/>
            </PrivateRoute>
            <PrivateRoute path="/entry/:id/edit" exact={true}>
                <EntryEdit />
            </PrivateRoute>
            <PrivateRoute path="/entry/:id" exact={true}>
                <EntryView />
            </PrivateRoute>
            <PrivateRoute path="/newentry" exact={true}>
                <CreateEntry />
            </PrivateRoute>
        </Switch>
    )
}

export default Dictionary;