import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';


export default function LoadSpinner(props) {
    return (
        <>
            <div  />
            <div style={{ paddingTop: "100px", margin: 'auto' }}>
                <Paper style={{ position: 'relative' }} elevation={0}>
                    <CircularProgress
                        size={40}
                        left={-20}
                        top={10}
                        status={'loading'}
                        style={{ marginLeft: '50%' }}
                    />
                </Paper>
            </div>
        </>
    )
}