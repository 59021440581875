import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CreateIcon from '@mui/icons-material/Create';
import { useAuth } from '../auth';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link as RouterLink } from 'react-router-dom';


export default function MainDrawer(props) {
    const auth = useAuth();


    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.setShowDrawer(false)
        // setState({ ...state, [anchor]: open });
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            {auth.role === "admin" ?
                <>
                    <List>
                        <ListItem button key={"createEntry"} component={RouterLink} to="/newentry">
                            <ListItemIcon>
                                <CreateIcon />
                            </ListItemIcon>
                            <ListItemText primary={"New Entry"} />


                        </ListItem>

                    </List>
                    <Divider />
                    </> : null}
            <List>
                <ListItem button key={"logout"} onClick={auth.signout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Drawer
            anchor="left"
            open={props.showDrawer}
            onClose={toggleDrawer(false)}
        >
            {list()}
        </Drawer>
    );
}
