import React, { useState, useEffect } from 'react';
import EntryMenu from "../components/EntryMenu"
import LoadSpinner from "../components/LoadSpinner"
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from '../auth';
import { styled } from "@mui/system";
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import EditForm from '../components/EditForm';

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export default function CreateEntry(props) {
  const auth = useAuth();
  const [submitError, setSubmitError] = useState(null)
  let history = useHistory();

  const submitForm = async (data) => {
    const submitData = { ...data, search_keys: [...data.keys] }
    const response = await auth.api.post(`/api/entry`, submitData)
    if (response.status !== 202) {
      setSubmitError(`Error (HTTP ${response.status}): ${response.body}`)
    } else {
      history.push(`/entry/${response.data.id}`)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubmitError(null);
  };

  return (
    <Container maxWidth="md">
      <EntryMenu />
      <Offset sx={{ mb: 2 }} />
      <EditForm entry={{}} onSubmit={submitForm} />

      <Snackbar open={submitError !== null} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {submitError}
        </Alert>
      </Snackbar>

    </Container>
  )
}


