import React, { useState, useEffect } from 'react';
import EntryMenu from "../components/EntryMenu"
import LoadSpinner from "../components/LoadSpinner"
import { useParams, useHistory } from "react-router-dom";
import { useAuth } from '../auth';
import { styled } from "@mui/system";
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import EditForm from '../components/EditForm';

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export default function EntryEdit(props) {
  const auth = useAuth();
  const { id } = useParams();
  const [entry, setEntry] = useState(null)
  const [submitError, setSubmitError] = useState(null)
  let history = useHistory();

  const submitForm = async (data) => {
    const submitData = {...data, search_keys: [...data.keys]}
    const response = await auth.api.put(`/api/entry/${id}`, submitData)
    if (response.status !== 202) {
      setSubmitError(`Error (HTTP ${response.status}): ${response.body}`)
    } else {
      history.push(`/entry/${id}`)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSubmitError(null);
  };


  useEffect(() => {
    const updateData = async () => {
      const response = await auth.api.get(`/api/entry/${id}`)
      setEntry(response.data)
    }
    updateData();
  }, [auth.api, id]);

  // const editForm = <Form onSubmit={(data) => submitForm(data)} onChange={setEntry} schema={DictEntrySchema} uiSchema={UISchema} formData={entry} />

  return (
    <Container maxWidth="md">
      <EntryMenu />
      <Offset sx={{ mb: 2 }} />
      {entry ? <EditForm entry={entry} onSubmit={submitForm}/> : <LoadSpinner />}

      <Snackbar open={submitError !== null} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {submitError}
        </Alert>
      </Snackbar>

    </Container>
  )
}


