import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
let theme = createTheme({
  palette: {
    primary: {
      main: '#D22631',
    },
    secondary: {
      main: '#FFC72C',
    },
    error: {
      main: red.A400,
    },
  },
});

theme = responsiveFontSizes(theme);
export default theme;
