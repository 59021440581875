import React, { useState, useReducer } from 'react';


// import Form from "@rjsf/core";
import FormControl from '@mui/material/FormControl';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';

import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Fade from '@mui/material/Fade';

const emptyState = { lang: [], keys: [], search_keys: [], trans: [], definitions: [], word: "", phonetics: "", type: "" };

const fabStyle = {
    margin: 0,
    top: 'auto',
    right: 40,
    bottom: 30,
    left: 'auto',
    position: 'fixed',
};


function reducer(state, action) {
    let tmpTrans = [...state.trans]
    switch (action.type) {
        case 'setvalue':
            return { ...state, [action.name]: action.value };
        case 'setWord':
            let newKeys = [...state.keys]
            let index = newKeys.indexOf(state.word.toLowerCase())
            if (index !== -1) {
                newKeys.splice(index, 1)
            }
            if (action.value.length >= 1) {
                newKeys = [action.value.toLowerCase(), ...newKeys]
            }
            return { ...state, word: action.value, keys: [...new Set(newKeys)] };
        case 'deleteKeyword':
            let tmp = [...state.keys]
            tmp.splice(action.index, 1)
            return { ...state, keys: tmp };
        case 'addKeyword':
            let updatedKeywords = [...state.keys, action.newKeyword.toLowerCase()]
            return { ...state, keys: [...new Set(updatedKeywords)] };
        case 'addTranslation':
            return { ...state, trans: [...state.trans, { english: "", lang: [], notes: [], examples: [] }] };
        case 'setTranslation':
            tmpTrans[action.index].english = action.value
            return { ...state, trans: tmpTrans };
        case 'deleteTranslation':
            tmpTrans.splice(action.index, 1)
            return { ...state, trans: tmpTrans };
        case 'addTranslationNote':
            tmpTrans[action.index].notes = tmpTrans[action.index].notes ? [...tmpTrans[action.index].notes, ""] : [""]
            return { ...state, trans: tmpTrans };
        case 'addTranslationExample':
            tmpTrans[action.index].examples = tmpTrans[action.index].examples ? [...tmpTrans[action.index].examples, { en: "", gr: "" }] : [{ en: "", gr: "" }]
            return { ...state, trans: tmpTrans };
        case 'setTranslationNote':
            tmpTrans[action.ti].notes[action.tn] = action.value
            return { ...state, trans: tmpTrans };
        case 'setTranslationExample':
            tmpTrans[action.ti].examples[action.te][action.name] = action.value
            return { ...state, trans: tmpTrans };
        case 'deleteTranslationNote':
            tmpTrans[action.ti].notes.splice(action.tn, 1)
            return { ...state, trans: tmpTrans };
        case 'deleteTranslationExample':
            tmpTrans[action.ti].examples.splice(action.te, 1)
            return { ...state, trans: tmpTrans };
        default:
            throw new Error();
    }
}

export default function EditForm(props) {
    const [state, dispatch] = useReducer(reducer, { ...emptyState, ...props.entry, keys: [...new Set([...{ ...emptyState, ...props.entry }.keys, ...{ ...emptyState, ...props.entry }.search_keys])] });
    const [newKeyword, setNewKeyword] = useState("");

    const addKeyword = () => {
        dispatch({ type: 'addKeyword', newKeyword: newKeyword })
        setNewKeyword("")
    }

    const addTranslation = () => {
        dispatch({ type: 'addTranslation' })
    }

    const addTranslationNote = (e, i) => {
        dispatch({ type: 'addTranslationNote', index: i })
    }

    const addTranslationExample = (e, i) => {
        dispatch({ type: 'addTranslationExample', index: i })
    }

    const deleteTranslation = (e, i) => {
        dispatch({ type: 'deleteTranslation', index: i })
    }

    const deleteKeyword = (e, i) => {
        dispatch({ type: 'deleteKeyword', index: i })
    }

    const handleTranslationChange = (e, i) => {
        dispatch({ type: 'setTranslation', index: i, value: e.target.value })
    }

    const updateTranslationNote = (e, i, j) => {
        dispatch({ type: 'setTranslationNote', ti: i, tn: j, value: e.target.value })
    }
    const updateTranslationExample = (e, i, j) => {
        dispatch({ type: 'setTranslationExample', ti: i, te: j, name: e.target.name, value: e.target.value })
    }

    const deleteTranslationNote = (e, i, j) => {
        dispatch({ type: 'deleteTranslationNote', ti: i, tn: j })
    }
    const deleteTranslationExample = (e, i, j) => {
        dispatch({ type: 'deleteTranslationExample', ti: i, te: j })
    }

    const handleChange = (e) => {
        if (["lang-gr", "lang-yy", "lang-yr"].includes(e.target.name)) {
            let lang = e.target.name.split('-')[1].toUpperCase();
            let current = [...state.lang];
            if (current.includes(lang)) {
                current = current.filter(item => lang !== item);
            } else {
                current = [...current, lang];
            }
            dispatch({ type: 'setvalue', name: "lang", value: current.sort() })
        } else if (e.target.name === "word") {
            dispatch({ type: 'setWord', name: e.target.name, value: e.target.value })
        } else {
            dispatch({ type: 'setvalue', name: e.target.name, value: e.target.value })
        }
    }

    console.log(state)
    return (
        <>
            <Fade in={true}>
                <Fab color="secondary" aria-label="edit" style={fabStyle} onClick={() => props.onSubmit(state)}>
                    <SaveIcon />
                </Fab>
            </Fade>
            <Box component="form" noValidate autoComplete="off" sx={{ '& .MuiTextField-root': { m: 1 }, }}>
                <Paper sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                }} md={null}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>
                                {state.ID ? "Edit Entry" : "Create Entry"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                required
                                id="word-field"
                                label="Gamilaraay Word"
                                name="word"
                                value={state.word}
                                onChange={handleChange}
                                variant="standard"
                                helperText="Gamilaraay Word or Prefix"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="ipa-field"
                                label="Phonetic Notation"
                                name="phonetics"
                                value={state.phonetics}
                                onChange={handleChange}
                                variant="standard"
                                helperText="Phonetic notation using the IPA"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="type-field"
                                label="Type"
                                name="type"
                                value={state.type}
                                onChange={handleChange}
                                variant="standard"
                                helperText="Type of speech of this word"
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Languages</FormLabel>
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={state.lang.includes('GR')} onChange={handleChange} name="lang-gr" size="small" />
                                        }
                                        label="GR"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={state.lang.includes('YR')} onChange={handleChange} name="lang-yr" size="small" />
                                        }
                                        label="YR"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={state.lang.includes('YY')} onChange={handleChange} name="lang-yy" size="small" />
                                        }
                                        label="YY"
                                    />
                                </FormGroup>
                                {/* <FormHelperText>Languages this word appears in</FormHelperText> */}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} >
                            <FormControl component="fieldset" variant="standard">
                                <FormLabel component="legend">Search Keywords</FormLabel>
                                <Grid item xs={12} sm={12} md={12} >
                                    {/* <Stack direction="row" spacing={1}> */}
                                    {state.keys.map((keyword, i) => {
                                        console.log(`keyword: ${keyword}, word: ${state.word}, keywords: ${state.keys}`)
                                        return state.word.toLowerCase() !== keyword.toLowerCase() ?
                                            <Zoom key={`keyword-${keyword}`} in={true}><Chip sx={{ mx: 0.3, my: 0.2 }} label={keyword} name={`keyword-${i}`} onDelete={(e) => { deleteKeyword(e, i) }} /></Zoom>
                                            : <Zoom key={`keyword-${keyword}`} in={true}><Chip sx={{ mx: 0.3, my: 0.2 }} label={keyword} name={`keyword-${i}`} /></Zoom>
                                    }
                                    )}
                                    {/* </Stack> */}
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} >
                                    <FormGroup aria-label="position" row>
                                        <TextField
                                            id="addkeyword"
                                            label="Add Keyword"
                                            name="addkeyword"
                                            variant="standard"
                                            value={newKeyword}
                                            onChange={(e) => setNewKeyword(e.target.value)}
                                            helperText="Add a keyword or short phrase to be used for the Search"
                                            onKeyDown={(e) => { if (e.key === 'Enter') { addKeyword(); }; }}
                                        />
                                        <Button disabled={newKeyword.length === 0} onClick={addKeyword} variant="contained" sx={{ ml: 3, mr: "auto", my: "auto" }}>Add</Button>
                                    </FormGroup>
                                </Grid>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                            <Stack direction="row" spacing={2} xs={12}>
                                <Button color="info" onClick={addTranslation}>Add Translation</Button>
                            </Stack>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} container spacing={3}>
                            {state.trans.map((trans, i) => {
                                return (
                                    <Grid key={`trans-${i}`} item xs={12} sm={12} md={12} >
                                        <Paper sx={{
                                            p: 2,
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}>
                                            <FormControl component="fieldset" variant="standard">
                                                <FormLabel component="legend">Translation #{i + 1}</FormLabel>
                                                <FormGroup aria-label="position" row>
                                                    <TextField
                                                        id={`english-field${i}`}
                                                        label="English"
                                                        name="english"
                                                        value={state.trans[i].english}
                                                        onChange={(e) => handleTranslationChange(e, i)}
                                                        variant="standard"
                                                        helperText="English translation of this word"
                                                        fullWidth
                                                    />

                                                    {trans.examples ? trans.examples.map((example, j) => {
                                                        return (
                                                            <Grid item xs={12} sm={12} md={12} key={`example-${i}-${j}`}>
                                                                <Paper sx={{
                                                                    p: 2,
                                                                    ml: 1,
                                                                    mb: 2,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}>
                                                                    <Typography variant="overline" display="block" gutterBottom>
                                                                        Example #{j + 1}
                                                                    </Typography>
                                                                    <Stack direction="row" spacing={2} xs={12}>
                                                                        <Stack direction="column" spacing={2} xs={12} sx={{ flexGrow: 1 }}>
                                                                            <TextField
                                                                                id={`example-${i}-${j}-gr`}
                                                                                label="Gamilaraay"
                                                                                name="gr"
                                                                                value={state.trans[i].examples[j].gr}
                                                                                onChange={(e) => updateTranslationExample(e, i, j)}
                                                                                fullWidth
                                                                                variant="standard"
                                                                            />
                                                                            <TextField
                                                                                id={`example-${i}-${j}-en`}
                                                                                label="English"
                                                                                name="en"
                                                                                value={state.trans[i].examples[j].en}
                                                                                onChange={(e) => updateTranslationExample(e, i, j)}
                                                                                fullWidth
                                                                                variant="standard"
                                                                            />
                                                                        </Stack>
                                                                        <Button color="warning" onClick={(e) => deleteTranslationExample(e, i, j)}>Delete</Button>

                                                                    </Stack>
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    }) : null}

                                                    {trans.notes ? trans.notes.map((note, j) => {
                                                        return (
                                                            <Grid item xs={12} sm={12} md={12} key={`note-${i}-${j}`} >
                                                                <Stack direction="row" spacing={2} xs={12}>
                                                                    <TextField
                                                                        id={`note-${i}-${j}`}
                                                                        label="Note"
                                                                        multiline
                                                                        rows={4}
                                                                        value={state.trans[i].notes[j]}
                                                                        onChange={(e) => updateTranslationNote(e, i, j)}
                                                                        sx={{ flexGrow: 1 }}
                                                                        fullWidth
                                                                    />
                                                                    <Button color="warning" onClick={(e) => deleteTranslationNote(e, i, j)}>Delete</Button>
                                                                </Stack>
                                                            </Grid>
                                                        )
                                                    }) : null}

                                                    <Grid item xs={12} sm={12} md={12} >
                                                        <Stack direction="row" spacing={2} xs={12}>
                                                            <Button color="info" onClick={(e) => addTranslationExample(e, i)}>Add Example</Button>
                                                            <Button color="info" onClick={(e) => addTranslationNote(e, i)}>Add Note</Button>
                                                            <Button color="warning" onClick={(e) => deleteTranslation(e, i)}>Delete</Button>
                                                        </Stack>
                                                    </Grid>
                                                </FormGroup>
                                            </FormControl>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Paper>
            </Box >
        </>

    )
}