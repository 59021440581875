import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useAuth } from '../auth';


const Example = styled("div")(({ theme }) => ({
    backgroundColor: "#F5F5F5",
    borderLeft: "solid 4px #AFAFAF",
    backgroundRepeat: "no-repeat",
    lineHeight: "18px",
    overflow: "hidden",
    padding: "15px 15px",
    marginTop: -10,
    marginLeft: 15,
}));

const Note = styled("div")(({ theme }) => ({
    backgroundColor: "#f0f7fb",
    borderLeft: "solid 4px #3498db",
    backgroundRepeat: "no-repeat",
    lineHeight: "18px",
    overflow: "hidden",
    padding: "15px 15px",
    marginTop: -10,
    marginLeft: 15,
}));

export default function EntryCard(props) {
    const auth = useAuth();

    return (
        <Container>
            <Card>
                <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                    </Typography>
                    <Typography variant="h5" >
                        {props.entry.word}
                    </Typography>
                    <Typography color="textSecondary">
                        {props.entry.type}
                    </Typography>
                    {props.entry.trans.map((row, i) => {
                        return (
                            <Typography variant="body2" component="div" style={{ marginTop: ((i > 0) ? 15 : 0) }} key={`en-row-${row.english}`}>
                                <Typography style={{ fontSize: "1.2em" }}>
                                    {"english" in row ? <span>{i + 1}. {row.english}</span> : null}
                                </Typography>
                                <br />
                                {(() => {
                                    if ("examples" in row) {
                                        return row.examples.map((ex, j) => {
                                            return (
                                                <Example key={`example-${i}-${j}`}>
                                                    <Typography style={{ fontStyle: 'bold', marginTop: 1, fontSize: "0.9em" }} variant="h1" >EXAMPLE</Typography><br />
                                                    <Typography style={{ fontStyle: 'italic', marginTop: 12 }} variant="body2" >{ex.gr}</Typography>
                                                    <Typography variant="body2" >{ex.en}</Typography>
                                                </Example>
                                            )
                                        })
                                    } else {
                                        return null;
                                    }
                                })()}

                                {(() => {
                                    if ("notes" in row) {
                                        let marginFirst = ("examples" in row) ? 25 : 0;
                                        return row.notes.map((note, j) => {
                                            return (
                                                <Typography variant="body2" component="div" style={{ marginTop: ((i > 0) ? 15 : marginFirst) }} key={`note-${i}-${j}`}>
                                                    <Note>
                                                        <Typography style={{ fontStyle: 'bold', marginTop: 1, fontSize: "0.9em" }} variant="h1" >NOTE</Typography><br />
                                                        <Typography style={{ fontStyle: 'italic', marginTop: 0 }} variant="body2" >{note}</Typography>
                                                        {/* <Typography variant="body2" >{ex.en}</Typography> */}
                                                        {/* // </Paper> */}
                                                    </Note>
                                                </Typography>

                                            )
                                        })
                                    } else {
                                        return null;
                                    }
                                })()}
                            </Typography>
                        )
                    })}

                </CardContent>

                { auth.role === "admin" ? <CardActions>
                    <Button size="small" href={`/entry/${props.entry.ID}/edit`}>Edit</Button>
                </CardActions> : null}
            </Card>
        </Container >
    );
}